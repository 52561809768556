import React from 'react';
import './Slides.css';
import Carousel from 'react-bootstrap/Carousel';
import City from '../../Resources/City1.png'
import 'bootstrap/dist/css/bootstrap.css';

function Slides(props){

  let slides = props.photos;

    return(
    <Carousel controls={false} id="Myslides">
      {
        slides.map((el, index)=>{
          return(
            <Carousel.Item key={index}>
              <img
                className="d-block w-100 h-50 slideImage"
                src={el}
                alt="First slide"
              />
            </Carousel.Item>
          )
        })
      }
    </Carousel>
    );
}

export default Slides;