import React, { useState, useEffect } from 'react';
import Resources from 'react'
import { renderIntoDocument } from 'react-dom/test-utils'
import './Navbar.css'
import '../../App.css'
import { Link } from "react-router-dom";
import MenuLogo from '../../Resources/MenuLogo.png'
import constant from '../../Constants.js'

function Navbar(){
    
    function openNav() {
        document.getElementById("mySidenav").style.transform = 'translateX(-0px)';
        // document.body.style.overflow = 'hidden';
    }
      
    function closeNav() {
        document.getElementById("mySidenav").style.transform = 'translateX(-100%)';
        // document.body.style.overflow = 'visible'
    }

    const [dropDown, setDropDown] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
      fetch(`${constant.url}api/rating/`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setDropDown(data);
        setLoading(false)
      });
    }, [])


    if(loading){
      return(
        <div className="MainNavbar" style={{display: 'none'}}>
        <div id="mySidenav" className="sidenav">
            <a href="#" className="closebtn" onClick={closeNav}>&times;</a>

            <Link to="/"><span onClick={closeNav}>About Us</span></Link>
            <Link to="/rankings"><span onClick={closeNav}>
              World Ratings
            </span></Link>
            <Link to="/news"><span onClick={closeNav}>Services</span></Link>
            <Link to="/contacts"><span onClick={closeNav}>Contacts</span></Link>

        </div>
        <div
          className="openMenuButton"
          style={{ fontSize: "30px", cursor: "pointer" }}
          onClick={openNav}
        >
          <img src={MenuLogo} alt="Menu"/>
        </div>
      </div>
      )
    }
    else{
      return (
        <div className="MainNavbar" style={{ display: "none" }}>
          <div id="mySidenav" className="sidenav">
            <a href="#" className="closebtn" onClick={closeNav}>
              &times;
            </a>

            <Link to="/">
              <span onClick={closeNav}>About Us</span>
            </Link>

            {
              dropDown.map((e, index)=>{
                let id = index + 1;
                return(
                  <Link to={"/rankings/"+id} key={index}>
                    <span onClick={closeNav}>{e.name}</span>
                  </Link>
                )
              })
            }
            <Link to="/news">
              <span onClick={closeNav}>Services</span>
            </Link>
            <Link to="/contacts">
              <span onClick={closeNav}>Contacts</span>
            </Link>
            <Link to="/subscription">
              <span onClick={closeNav}>Subscription</span>
            </Link>
          </div>
          <div
            className="openMenuButton"
            style={{ fontSize: "30px", cursor: "pointer" }}
            onClick={openNav}
          >
            <img src={MenuLogo} alt="Menu" />
          </div>
        </div>
      );
    }
}

export default Navbar;