import React, { useEffect, useState } from 'react';
import Slides from './Slides/Slides.js'
import './AboutUs.css'
import constant from '../Constants.js'
import Loading from '../Components/Loading/Loading.js'


function AboutUs(){
  const regex = /[<div class="Slider">]/g;

  const [aboutText, setAboutText] = useState([]);
  const [slides, setSlides] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingSlide, setLoadingSlide] = useState(true);
  let output = '';

  useEffect(()=>{
    fetch(`${constant.url}api/aboutus/`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setAboutText([data[0].text]);
        setLoading(false)
      }); 

      fetch(`${constant.url}api/slider/`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let temp=[];
        data.map((e)=>{
          temp.push(e.file)
        })
        setSlides(temp);
        setLoadingSlide(false)
      }); 
      
  }, [])

  if(loading || loadingSlide){
    return(
      <Loading/>
    )
  }
  else if(!loading && !loadingSlide){
    aboutText.map((e)=>{
      if(e.match('<div class="Slider"/>')){
        let splited = e.split('<div class="Slider"/>')
        let temp = [];
        for(let i=0; i<=splited.length-1; i++){
          temp.push(splited[i])
          if(i!==splited.length-1){
            temp.push('to be replaced')
          }
        }
        output = temp;
      }
      else{
        output = [aboutText];
      }
    })
    return (
      <>
        <Slides photos={slides}/>
        <div className="TextInfo">
          <div className="TextHeader">About Us</div>
          <div className="MainText">
            {
              output.map((e)=>{
                if(e=='to be replaced') return(<Slides photos={slides}/>)
                else {
                  return (<span id="aboutTxt"
                    dangerouslySetInnerHTML={{__html: e}}></span>)
                }
              })
            }
          </div> 
        </div>
      </>
    );
  }
}

export default AboutUs;