import {React, useState, useEffect} from 'react';
import './Footer.css';
import mail from '../../Resources/letter.png';
import constant from '../../Constants.js';

function Footer(){

    const [footerText, setFooterText] = useState('')
    const [loading, setLoading] = useState(true)
  
    useEffect(()=>{
      fetch(`${constant.url}api/contact_us/`)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setFooterText(data[0].text);
          setLoading(false)
        }); 
    }, [])


    if(loading){
        return(
            <div className="FooterContainer">
                <a href="mailto:painstyle01@gmail.com" 
                  id="mailLink"
                  style={{lineHeight: '25vh'}}
                >
                  <img src={mail}/>
                </a>
            </div>
        )
    }
    else{
      return (
        <div className="FooterContainer">
          <div className="footerText"
            dangerouslySetInnerHTML={{__html: footerText}}
          >
            
          </div>
          <a href="mailto:contact@ihbiagency.org" id="mailLink">
            <img src={mail} />
          </a>
        </div>
      );
    }
}

export default Footer;