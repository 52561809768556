import {React, useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';

import './Rankings.css'
import constant from '../Constants.js';
import City from '../Resources/City1.png'
import Loading from '../Components/Loading/Loading.js'
import Slides from '../AboutUs/Slides/Slides.js'

function Rankings(){

  const [rankingText, setRankingText] = useState('')
  const [loading, setLoading] = useState(true);
  const [slides, setSlides] = useState('');
  const params = useParams();

  let setMobileTable = function(selector) {
    if (window.innerWidth > 600 || loading==true) return false;


    let tableEl;

    if(document.getElementsByTagName("table").length>1){
      tableEl = document.getElementsByTagName("table")[1];
      if(document.getElementsByTagName("table")[0].classList.contains("test")){
        document.getElementsByTagName("table")[0].classList.remove("test")
      }
    } else {
      tableEl = document.getElementsByClassName("test")[0];
    }

    let thEls = tableEl.querySelectorAll('tr')[0];
    thEls.style.display = 'none'
    thEls = thEls.querySelectorAll('td');

    const tdLabels = Array.from(thEls).map(el => el.innerText);

    tableEl.querySelectorAll('tbody tr').forEach( tr => {
      Array.from(tr.children).forEach( 
        (td, ndx) =>  {
          td.setAttribute('label', tdLabels[ndx])
          td.style.height = 'auto';
        }
      );
    });
  }

  useEffect(()=>{
    setLoading(true)
    console.log(params)
    fetch(`${constant.url}api/rating/${params.id}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data)
        setRankingText(data);
        setLoading(false)
        setMobileTable();
      }); 

      fetch(`${constant.url}api/slider/`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let temp=[];
        data.map((e)=>{
          temp.push(e.file)
        })
        setSlides(temp);
      }); 

  }, [params])

  useEffect(()=>{
    setMobileTable();
  }, [loading])

  function getFile(myUrl, name){
    fetch(myUrl)
    .then(response => {
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = `${name}.pdf`;
        a.click();
      });
    });
  }


  if(loading){
    return(
      <Loading/>
    )
  }
  else{
    return (
      <div>
      <Slides photos={slides}/>
        <div>
          <div className="TextInfo">
            <div className="TextHeader">{rankingText.name}</div>
            <div
              className="MainText"
              dangerouslySetInnerHTML={{ __html: rankingText.text }}
            ></div>
            </div>
          </div>
        </div>
    );
  }
}

export default Rankings;