import React, { useState, useEffect } from 'react';
import './Header.css';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from "react-router-dom";
import Logo from '../../Resources/logo.png';
import Navbar from '../Navbar/Navbar.js';
import constant from '../../Constants.js'

import MyConstants from '../../Constants.js';


function Header(){

    const [language, SetLanguage] = useState('ENG');
    const [page, SetPage] = useState('About');
    const [loading, setLoading] = useState(true);
    const [dropDown, setDropDown] = useState('')

  
  
  function handleLang(language){
      SetLanguage(language)
  }

  function handlePage(chosenPage){
    localStorage.setItem('CurrentPage', chosenPage)
    let pages = [...document.getElementById('HeaderMenu').children]
    pages.map((e)=>{
      if(e.firstChild.id == page){
        console.log(page)
        e.firstChild.classList.remove('activePage')
      }
    })
    document.getElementById(chosenPage).classList.add("activePage")
    SetPage(chosenPage);
  }



  useEffect(()=>{
    let p = localStorage.getItem('CurrentPage')
    if(p == null){
      localStorage.setItem('CurrentPage', page)
    }
    // document.getElementById(p).classList.add("activePage")
    SetPage(p)

    if(window.innerWidth <= 800){
      document.getElementsByClassName('MainNavbar')[0].style.display = `block`;
      document.getElementById('HeaderMenu').style.display = `none`;
    }
    else if(window.innerWidth > 800){
      document.getElementsByClassName('MainNavbar')[0].style.display = `none`;
      document.getElementById('HeaderMenu').style.display = `block`;
    }

    fetch(`${constant.url}api/rating/`)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      setDropDown(data);
      setLoading(false)
    });
  }, [])

  function changeRanking(){
    // window.location.reload();
  }

  if(loading){
    return(
      <div className="HeaderContainer">
      <div className="HeaderLogo">
        <div id="smallHeaderMenu"><Navbar/></div>
        <span id="logo"><img src={Logo} alt="logo"/></span>
        <span id="language">
          <Dropdown>
            <Dropdown.Toggle id="dropdown">
              {language}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={()=>{handleLang('ENG')}}>ENG</Dropdown.Item>
              {/* <Dropdown.Item onClick={()=>{handleLang('UKR')}}>UKR</Dropdown.Item>
              <Dropdown.Item onClick={()=>{handleLang('RUS')}}>RUS</Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </span>
      </div>
      <div id="HeaderMenu">
        <Link to="/" >
          <span onClick={()=>{handlePage('About')}} id="About">About Us</span>
        </Link>

        <a href="#">
            <div className="dropdown">
              <div className="dropbtn">World Rating</div>
              <div className="dropdown-content">
                <Link to="#">Loading...</Link>
              </div>
            </div>
        </a>

        <Link to="/news" >
          <span id="News" onClick={()=>{handlePage('News')}}>Services</span>
        </Link>
        
        <Link to="/contacts">
          <span id="Contact" onClick={()=>{handlePage('Contact')}}>Contacts</span>
        </Link>

        <Link to="/subscription">
            <span id="Subscription" onClick={()=>{handlePage('Subscription')}} className="blank">Subscription</span>
        </Link>
      </div>
    </div>
  );
  }
  else{
    return (
      <div className="HeaderContainer">
        <div className="HeaderLogo">
          <div id="smallHeaderMenu"><Navbar/></div>
          <span id="logo"><img src={Logo} alt="logo"/></span>
          <span id="language">
            <Dropdown>
              <Dropdown.Toggle id="dropdown">
                {language}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={()=>{handleLang('ENG')}}>ENG</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </span>
        </div>
        <div id="HeaderMenu">
          <Link to="/" >
            <span onClick={()=>{handlePage('About')}} id="About">About Us</span>
          </Link>

          <a href="#">
              <div className="dropdown">
                <div className="dropbtn">World Ratings</div>
                <div className="dropdown-content">
                  {
                    dropDown.map((e, index)=>{
                      let id = index+1;
                      return(
                        <Link to={'/rankings/'+id} key={index} onClick={()=>{changeRanking()}}>{e.name}</Link>
                      )
                    })
                  }
                </div>
              </div>
          </a>

          <Link to="/news" >
            <span id="News" onClick={()=>{handlePage('News')}}>Services</span>
          </Link>
          
          <Link to="/contacts">
            <span id="Contact" onClick={()=>{handlePage('Contact')}}>Contacts</span>
          </Link>

          <Link to="/subscription">
            <span id="Subscription" onClick={()=>{handlePage('Subscription')}} className="blank">Subscription</span>
          </Link>
        </div>
      </div>
    );
  }
}

export default Header;
