import React from 'react';
import './Loading.css'

function Loading(){
    return(
        <div style={{minHeight: '70vh'}}>
           <div class="loading">&#8230;</div>
        </div>
    )
}

export default Loading;