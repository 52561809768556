import {React, useState, useEffect} from 'react';
import constant from '../Constants.js';
import './News.css'
import Slides from '../AboutUs/Slides/Slides.js'
import Loading from '../Components/Loading/Loading.js'

function News(){

  const [newsText, setNewsText] = useState('')
  const [loading, setLoading] = useState(true);
  const [slides, setSlides] = useState('');

  useEffect(()=>{
    fetch(`${constant.url}api/news/`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setNewsText(data[0].text);
      }); 

      fetch(`${constant.url}api/slider/`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let temp=[];
        data.map((e)=>{
          temp.push(e.file)
        })
        setSlides(temp);
        setLoading(false)
      }); 
      
  }, [])


  if(loading){
    return(
      <Loading/>
    )
  }
  else{
    return (
      <>
        <Slides photos={slides}/>
        <div className="TextInfo">
          <div className="TextHeader">Services</div>
          <div
            className="MainText"
            dangerouslySetInnerHTML={{ __html: newsText }}
          ></div>
        </div>
      </>
    );
  }
}

export default News;