import logo from './logo.svg';
import './App.css';
import AboutUs from './AboutUs/AboutUs.js';
import Rankings from './Rankings/Rankings.js'
import News from './News/News.js';
import ContactUs from './ContactUs/ContactUs.js';
import Footer from './Components/Footer/Footer.js';
import Header from './Components/Header/Header.js';
import Navbar from './Components/Navbar/Navbar.js'
import Subscription from './Subscription/Subscription.js';
import MobileRatings from './MobileRatins/MobileRatings.js'
import {useParams} from 'react-router-dom';


import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";

function App() {
  return (
    <div className="myContainer">
      <HashRouter>
        <Header />
        <Routes>
          <Route path="/" element={<AboutUs />} />
          <Route path="/rankings/:id" element={<Rankings />} />
          <Route path="/news" element={<News />} />
          <Route path="/contacts" element={<ContactUs />} />
          {/* <Route path="/ratingsList" element={<MobileRatings />} /> */}
          <Route path="/subscription" element={<Subscription />} />
        </Routes>
        <Footer />
      </HashRouter>
    </div>
  );
}

export default App;