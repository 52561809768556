import {React, useState, useEffect} from 'react';
import Form from 'react-bootstrap/Form';
import './Subscription.css'
import constant from '../Constants.js'

function Subscription(){
  const [validated, setValidated] = useState(false);

    function emailValidator(){
        let val = document.getElementById('emailPut')
        document.getElementById("formBtn").innerText = 'Send info'
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(val.value))
        {
          setValidated(true);
        } else setValidated(false);
    }

    function sendEmail(){ 
      if (validated) {
        let val = document.getElementById("emailPut").value;
        let btn = document.getElementById("formBtn");
        btn.innerText = 'Sending...'
        const requestOptionsModel = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email: val,
          }),
        };

        fetch(`${constant.url}api/save_email`, requestOptionsModel)
          .then((response) => {
            if (response.status === 200) {
              return response.text();
            } else {
              console.log(response.status);
            }
          })
          .then((data) => {
            btn.innerText = 'Success!'
          })
          .catch((error) => {
            console.log(error);
          });
      } else{
        alert('Please, enter a valid email')
      }
      
    }

    return (
      <div className="TextInfo"  style={{ minHeight: "60vh" }}>
        <div id="formText">Subscribe tp our mailing list!</div>
        <div className="subGrid">
          <Form id="form" onChange={()=>{emailValidator()}} validated={validated}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="name@example.com" id="emailPut"/>
            </Form.Group>
          </Form>
          <div className="myBtn" id="formBtn" onClick={()=>{sendEmail()}}>
            Send info
          </div>
        </div>
      </div>
    );
};

export default Subscription;